<template>
    <div class="table">
    	<el-table :data="tableData" border style="width: 100%">
    	    <el-table-column prop="type" label="统计类型" width="180"></el-table-column>
    	    <el-table-column prop="sum"  label="总量" width="180"></el-table-column>
    	    <el-table-column prop='data.0'                     label='0时' align='center' />
    	    <el-table-column prop='data.1'                     label='1时' align='center' />
    	    <el-table-column prop='data.2'                     label='2时' align='center' />
    	    <el-table-column prop='data.3'                     label='3时' align='center' />
    	    <el-table-column prop='data.4'                     label='4时' align='center' />
    	    <el-table-column prop='data.5'                     label='5时' align='center' />
    	    <el-table-column prop='data.6'                     label='6时' align='center' />
    	    <el-table-column prop='data.7'                     label='7时' align='center' />
    	    <el-table-column prop='data.8'                     label='8时' align='center' />
    	    <el-table-column prop='data.9'                     label='9时' align='center' />
    	    <el-table-column prop='data.10'                     label='10时' align='center' />
    	    <el-table-column prop='data.11'                     label='11时' align='center' />
    	    <el-table-column prop='data.12'                     label='12时' align='center' />
    	    <el-table-column prop='data.13'                     label='13时' align='center' />
    	    <el-table-column prop='data.14'                     label='14时' align='center' />
    	    <el-table-column prop='data.15'                     label='15时' align='center' />
    	    <el-table-column prop='data.16'                     label='16时' align='center' />
    	    <el-table-column prop='data.17'                     label='17时' align='center' />
    	    <el-table-column prop='data.18'                     label='18时' align='center' />
    	    <el-table-column prop='data.19'                     label='19时' align='center' />
    	    <el-table-column prop='data.20'                     label='20时' align='center' />
    	    <el-table-column prop='data.21'                     label='21时' align='center' />
    	    <el-table-column prop='data.22'                     label='22时' align='center' />
    	    <el-table-column prop='data.23'                     label='23时' align='center' />
    	</el-table>
    </div>
</template>
<script>
export default {
	props:['statisticsData'],
    data(){
        return {
            loading:false,
			tableData:[],
        }
    },
    mounted() {
        this.LoadDatas();
    },
    methods: {
        LoadDatas(){
			let data = this.statisticsData;
			this.tableData[0] = data.banner;
			this.tableData[1] = data.insert;
			this.tableData[2] = data.incentive;
			this.tableData[3] = data.user;
        },
    },
}
</script>